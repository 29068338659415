<template>
  <div>
    <b-modal
      ref="modal_recruitment_process"
      v-model="onControl"
      size="sm"
      :title="'New Password for ' + employee.first_name + ' ' + employee.last_name"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="close"
    >
      <validation-observer ref="observer">
        <b-row class="my-1">
          <b-col>
            <b-form-group label="New Password">
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:50|specialpassword|password:@confirm"
              >
                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  type="password"
                  placeholder="Example@123"
                  :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>
                  New Password {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <b-form-group label="Confirm Password">
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:50"
                name="confirm"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="confirmPassword"
                  type="password"
                  placeholder="Example@123"
                  :state="errors[0] ? false :valid ? true : null"
                />
                <b-form-invalid-feedback>
                  Confirm Password {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            variant="primary"
            @click="save()"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { VMoney } from 'v-money';
import { mapGetters } from 'vuex';
import Services from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  components: {
  },
  directives: { money: VMoney },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      userId: null,
      result: null,
      valid: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'DebtSolutionClients/G_CLIENTS',
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
  },
  async created() {
    this.onControl = true;
  },
  // async mounted() {
  // },
  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      const validation = await this.$refs.observer.validate();
      if (!validation) return;
      this.userId = this.currentUser.user_id;
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const params = {
          newPassword: this.newPassword,
          userId: this.employee.id,
          currentUser: this.userId,
        };
        const result = await Services.addNewEmployeePassword(params);
        this.$emit('password-saved');
        this.errorMessage = false;
        this.result = result;
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>
<style scope>
</style>
