export default [
  // {
  //   key: 'id',
  //   label: 'Id',
  //   class: 'h-auto py-1',
  // },
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'name',
    label: 'Name',
  },
  // {
  //   key: 'last_name',
  //   label: 'Last Name',
  //   class: 'h-auto py-1',
  // },
  {
    key: 'mobile',
    label: 'Mobile',
  },
  {
    key: 'work_email',
    label: 'Work Email',
  },
  {
    key: 'department_name',
    label: 'Department',
  },
  {
    key: 'rol_by_department',
    label: 'Rol by Dept',
  },
  {
    key: 'module_name',
    label: 'Module',
  },
  {
    key: 'rol_by_module',
    label: 'Rol by Module',
  },
  {
    key: 'corporate_mail_created',
    label: 'Corp. Mail Status',
  },
  {
    key: 'created_at',
    label: 'date of creation',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
