<template>
  <div>

    <header-slot />
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="resetAllFilters"
      @reload="refreshTable"
    >
      <template #table>
        <b-table
          slot="table"
          ref="newemployees"
          show-empty
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="getNewEmployees"
          :fields="fields"
          primary-key="id"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <!-- NUMBER -->
          <template #cell(number)="data">
            {{ data.item.number }}
          </template>

          <!-- NAME -->
          <template #cell(name)="data">
            {{ data.item.first_name }}
            {{ data.item.last_name }}
          </template>

          <!-- MOBILE -->
          <template #cell(mobile)="data">
            {{ data.item.mobile }}
          </template>

          <!-- WORK EMAIL -->
          <template #cell(work_email)="data">
            {{ data.item.work_email }}
          </template>

          <!-- DEPARTMENT ID -->
          <template #cell(department_name)="data">
            {{ data.item.department_name }}
          </template>

          <!-- ROL BY DEPARTMENT -->
          <template #cell(rol_by_department)="data">
            {{ data.item.rol_by_dpt_name }}
          </template>

          <!-- MODULE -->
          <template #cell(module_name)="data">
            <div v-if="data.item.json_result">


            <div
              v-for="(item, index) in JSON.parse(data.item.json_result).slice(0, totalShowModules)"
              :key="index"
            >
              {{ item.module_name }}
            </div>
            <template v-if="arrayShowModule.includes(data.index)">
              <div
                v-for="(item2) in JSON.parse(data.item.json_result).slice(totalShowModules)"
                :key="item2.id"
              >
                {{ item2.module_name }}
              </div>
            </template>
            <b-badge
              v-if=" data.item.json_result? JSON.parse(data.item.json_result).length > totalShowModules : false"
              variant="dark"
              class="cursor-pointer"
              @click="showmore(data.index)"
            >
              <!-- <feather-icon
                icon="MoreVerticalIcon"
                size="15"
              /> -->
              see more
            </b-badge>
            </div>
          </template>

          <!-- ROL BY MODULE -->
          <template #cell(rol_by_module)="data">
            <div v-if="data.item.json_result ? JSON.parse(data.item.json_result)[0].role_name === 'CEO' :false ">
              CEO
            </div>

            <template v-else>

              <div v-if="data.item.json_result">

                <div
                  v-for="(item, index) in JSON.parse(data.item.json_result).slice(0,totalShowRoles)"

                  :key="index"
                >
                  {{ item.role_name }}
                </div>
                <template v-if="arrayShowRoles.includes(data.index)">
                  <div
                    v-for="(item2) in JSON.parse(data.item.json_result).slice(totalShowRoles)"
                    :key="item2.id"
                  >
                    {{ item2.role_name }}
                  </div>
                </template>
                <b-button
                  v-if="JSON.parse(data.item.json_result).length > totalShowRoles"
                  variant="outline-primary"
                  size="sm"
                  @click="showmoreRoles(data.index)"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="15"
                  />
                  see more
                </b-button>

              </div>
            </template>

          </template>

          <!-- CORPORATE MAIL CREATED -->
          <template #cell(corporate_mail_created)="data">
            <span
              :class="{
                'text-primary': data.item.corporate_mail_created === 'PENDING',
                'text-success': data.item.corporate_mail_created === 'COMPLETED',
                'text-danger': data.item.corporate_mail_created === 'REJECTED'
              }"
            >
              {{ data.item.corporate_mail_created }}
            </span>
          </template>

          <!-- CREATED AT -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobal }}
            {{ data.item.created_at | myHourTime }}

          </template>

          <!-- OPTIONS -->
          <template
            #cell(actions)="data"
          >
            <b-dropdown
              v-if="data.item.corporate_mail_created !== 'COMPLETED'"
              :disabled="data.item.corporate_mail_created === 'COMPLETED'"
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-tooltip.hover.left="'Mail creation confirmed'"
                @click="openModalAdd(data.item)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-success"
                />
                <span class="pl-1 text-success">COMPLETED</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.corporate_mail_created !== 'REJECTED'"
                v-b-tooltip.hover.left="'Creation of rejected mail'"
                @click="updateNewEmployees(data, 3)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="text-danger"
                />
                <span class="pl-1 text-danger">REJECTED</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              v-if="data.item.corporate_mail_created === 'COMPLETED'"
              size="sm"
              variant="dark"
              @click="openModalAdd(data.item)"
            >
              <feather-icon
                icon="UnlockIcon"
                size="15"
              />
            </b-button>
                        <feather-icon
                          v-if="data.item.corporate_mail_created === 'COMPLETED'"
                          icon="AlignJustifyIcon"
                          size="15"
                          class="text-info ml-1 cursor-pointer"
                          @click="openModalTracking(data.item)"
                        />
          </template>

        </b-table>
      </template>
    </filter-slot>
    <modal-add-password
      v-if="openModalAddPassword"
      :employee="employeeData"
      @close="closeModalAdd"
      @password-saved="refreshTable"
    />
    <modal-tracking-password
      v-if="openModalTrackingPassword"
      :employee="employeeData"
      @close="closeModalTracking"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FieldsNewEmployees from '@/views/paragon/views/new-employees/data/fields-new-employees';
import FiltersData from '@/views/paragon/views/new-employees/data/filters.data';
import EmployeesService from '@/views/management/views/employees/employees.service';
import HelpDesk from '@/views/commons/components/helpdesk/services/helpdesk.service';
import ModalAddPassword from '@/views/commons/components/recruitment-process/components/modals/ModalAddPassword.vue';
import ModalTrackingPassword from '@/views/commons/components/recruitment-process/components/modals/ModalTrackingPassword.vue';

export default {
  // If you reuse this component, values,filters and fields are hardcoded
  components: {
    ModalAddPassword,
    ModalTrackingPassword,
  },
  data() {
    return {
      openModalAddPassword: false,
      openModalTrackingPassword: false,
      employeeData: null,
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      sortBy: 'created_at',
      sortDesc: true,
      fields: FieldsNewEmployees,
      startPage: null,
      toPage: null,
      endPage: '',
      nextPage: '',
      isBusy: false,
      // perPageOptions: [10, 25, 50, 100],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Client...',
        model: '',
      },
      dataNewEmployees: [],
      selectedRowId: null,
      selectedState: null,
      showAllModules: false,
      arrayShowModule: [],
      arrayShowRoles: [],
      totalShowModules: 1,
      totalShowRoles: 1,
      active: true,
      filter: FiltersData,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
    hidebutton() {
      return !this.active;
    },
  },
  async created() {
    await Promise.all([
    ]);
  },
  async mounted() {
    await this.getDepartments();
    await this.getNewEmployees();
  },
  methods: {
    openModalAdd(employee) {
      this.openModalAddPassword = true;
      this.employeeData = employee;
    },
    closeModalAdd() {
      // this.refreshTable();
      this.openModalAddPassword = false;
    },
    showmore(index) {
      if (this.arrayShowModule.includes(index)) {
        this.arrayShowModule = this.arrayShowModule.filter(item => item !== index);
        this.arrayShowRoles = this.arrayShowRoles.filter(item => item !== index);
        return;
      }
      this.arrayShowModule.push(index);
      this.arrayShowRoles.push(index);
    },
    showmoreRoles(index) {
      if (this.arrayShowRoles.includes(index)) {
        this.arrayShowRoles = this.arrayShowRoles.filter(item => item !== index);
        return;
      }
      this.arrayShowRoles.push(index);
    },
    async updateNewEmployees(data, state) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const ctx = {
          selectedRowId: data.item.id,
          selectedState: state,
        };
        const response = await HelpDesk.updateNewEmployees(ctx);
        if (response.status === 200) {
          this.refreshTable();
        }

        // console.error('Error en la actualización:', response.status, response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getNewEmployees() {
      try {
        const ctx = {
          perPage: this.paginate.perPage,
          currentPage: this.paginate.currentPage,
          department: this.filter[0].model,
          employee: this.filterPrincipal.model,
        };
        const response = await HelpDesk.getNewEmployees(ctx);
        if (response.status === 200) {
          this.dataNewEmployees = response.data.data;
          this.startPage = response.data.from;
          this.paginate.currentPage = response.data.current_page;
          this.paginate.perPage = response.data.per_page;
          this.totalRows = response.data.total;
          this.toPage = response.data.to;
          console.log(this.dataNewEmployees);
          return this.dataNewEmployees;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },
    resetAllFilters() {
      this.filter.forEach(filter => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.employeesList.refresh();
    },
    async getDepartments() {
      try {
        const params = {};
        const data = await EmployeesService.getAllDepartments(params);

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred
        this.filter[0].options = this.department;
        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    refreshTable() {
      this.$refs.newemployees.refresh();
    },
    closeModalTracking() {
      this.openModalTrackingPassword = false;
    },
    openModalTracking(data) {
      this.openModalTrackingPassword = true;
      this.employeeData = data;
    },
  },
};
</script>

<style scoped>

</style>
