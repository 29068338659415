<template>
  <div>
    <b-modal
      ref="modal_tracking_password"
      size="lg"
      :title="'Tracking Password'"
      modal-class="modal-primary"
      hide-footer
      @hidden="close"
    >
      <template #modal-title>
        <div class="d-flex justify-content-start align-items-center text-white">
          <h4 class="m-0 p-0 text-white">
            Password Tracking: "{{ employee.first_name }}  {{ employee.last_name }}"
          </h4>
        </div>
      </template>
      <filter-slot
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="filters"
        :filter-principal="{}"
        :no-visible-principal-filter="true"
        @reload="$refs['refTrackingPassword'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingPassword"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="60vh"
            primary-key="id"
            responsive="sm"
            :items="getTracking"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(created_by)="data">
              <span>{{ data.item.created_by_name }}</span>
              <br>
              <span>{{ data.item.created_at | myGlobalWithHour }} </span>
            </template>

            <template #cell(updated_by)="data">
              <span>{{ data.item.updated_by_name }}</span>
              <br>
              <span>{{ data.item.updated_at | myGlobalWithHour }} </span>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Services from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  components: {
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'password',
          sortable: false,
          label: 'Password',
          visible: true,
        },
        {
          key: 'created_by',
          sortable: false,
          label: 'Created By',
          visible: true,
        },
        {
          key: 'updated_by',
          sortable: false,
          label: 'Updated By',
          visible: true,
        },
      ],
      filters: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      trackingItems: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async mounted() {
    this.toggleModal('modal_tracking_password');
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async getTracking(ctx) {
      const response = await Services.getTrackingPassword({
        page: ctx.currentPage,
        perPage: ctx.perPage,
        user_id: this.employee.user_id,
      });
      this.trackingItems = response.data.data;
      this.startPage = response.data.from;
      this.paginate.currentPage = response.data.current_page;
      this.paginate.perPage = response.data.per_page;
      this.totalRows = response.data.total;
      this.toPage = response.data.to;
      return this.trackingItems || [];
    },
  },
};
</script>
  <style scope>
  </style>
